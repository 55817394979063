<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="dataRegularTrx"
      class="elevation-1"
			dense
			:search="search"
    >
      <template v-slot:top>
        <v-toolbar flat color="white">
          <v-toolbar-title>Regular Transactions</v-toolbar-title>
          <v-spacer></v-spacer>
        <v-text-field
        v-model="search"
        append-icon="search"
        label="Search"
        single-line
        hide-details
      ></v-text-field> 
		<vue-excel-xlsx
			:data="dataRegularTrx"
			:columns="columnHeaders"
				:file-name="module_name"
			>
			<v-btn icon alt class="mt-2" :color="dataRegularTrx.length=== 0 ? 'gray' : 'primary'" :disabled="dataRegularTrx.length===0"><v-icon>mdi-arrow-down-bold-circle</v-icon> </v-btn>
		</vue-excel-xlsx>
        </v-toolbar>
      </template>
      <template v-slot:item.action="{ item }">
		<v-icon
        color="grey"
        class="mr-2"
        small
		v-if="role_access < 4"
      >
        mdi-delete-off
      </v-icon>
      <v-icon
        small
		v-else
        color="red"
        @click="deleteItem(item)"
      >
        delete
      </v-icon>
      </template>
    </v-data-table>
    <!-- <create-regularTxns /> -->
  </div>
</template>

<script>
// import createRegularTransactions from './createRegularTransactions.vue';
import { mapGetters } from 'vuex';

export default {
	data(){
		return {
			module_name: 'Regular Transactions',
			dataRegularTrx: [],
			nextPageRegularTrx: '',
			search: '',
			role_access: 1,
		};
	},
	components: {
		// 'create-regularTxns': createRegularTransactions
	},
	computed: {
		...mapGetters( {
			regulartxns: 'regularTxns/regulartxns',
			headers: 'regularTxns/headers',
			columnHeaders: 'regularTxns/columnHeaders',
			editedItem: 'regularTxns/editedItem',
			defaultItem: 'regularTxns/defaultItem',
			dialog: 'regularTxns/dialog',
			editedIndex: 'regularTxns/editedIndex',
			isLoading: 'regularTxns/isLoading',
			valid: 'regularTxns/valid',
			currUser: 'auth/currUser'
		}),
	},
	watch: {
		regulartxns: {
			handler() {
				this.getRegTrx();
			}
		}
	},
	mounted () {
		this.$store.dispatch('regularTxns/getregularTxns');
		this.$store.dispatch('approvinglevel/getApprovingLevels');
		this.$store.dispatch('user/getUsers');
		this.$store.dispatch('currency/getCurrencies');
		let roles = this.currUser.roleaccess;
		if(roles.length > 0){
			let roleaccess = roles.filter(r => r.module_name === this.module_name);
			if(roleaccess.length > 0){
				this.role_access = roleaccess[0].access_level;
			}
		}
	},
	methods: {
		editItem (item) {
			this.$store.dispatch('regularTxns/setEditedIndex', this.regulartxns.indexOf(item));
			this.$store.dispatch('regularTxns/setEditedItems', Object.assign({}, item));
			this.$store.dispatch('regularTxns/setDialog', true);
			this.$store.dispatch('regularTxns/setValid', true);
			this.$store.dispatch('regularTxns/setformTitle', 'Edit Entry');
		},
		deleteItem (item) {
			this.$swal.fire({
				title: `Remove ${item.txn_type} from the list?`,
				text: 'You won\'t be able to revert this!',
				type: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Yes, delete it!'
			}).then((result) => {
				if (result.value) {
					this.$store.dispatch('regularTxns/setEditedIndex', this.regulartxns.data.indexOf(item));
					this.$store.dispatch('regularTxns/deleteregularTxns', item.id);
				}
			});
		},
		async getRegTrx(){
			let data = this.regulartxns.data;
			if(data.length > 0){
				this.dataRegularTrx = this.regulartxns.data.filter(e => parseInt(e.edit_by) === parseInt(this.currUser.id));
				this.nextPageRegularTrx= this.regulartxns.next_page_url;
				let page = 2;

				while (this.nextPageRegularTrx !== null){
					await this.$store.dispatch('regularTxns/getData', page).then(response => {
						this.nextPageRegularTrx = response.data.data.next_page_url;
						response.data.data.data.forEach(details => {
							if(parseInt(details.edit_by) === parseInt(this.currUser.id)){
								this.dataRegularTrx.push(details);
							}
						});
					});
					page++;
				}
			}
		}
	}
};
</script>